import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
.wrapper {
  display: flex;
  &.jc-sb {
    justify-content: space-between;
  }
  &.jc-fs {
    justify-content: flex-start;
  }
  &.jc-fe {
    justify-content: flex-end;
  }
  &.ai-c {
    align-items: center;
  }
}
.block {
  padding: 20px 30px;
  flex: 1;
  &.flex-2{
    flex:2;
  }
}

.form-group:not(:last-child){
  margin-bottom: 25px;
}

//typography

.text-default{
  font-size: 13px;
  line-height: 20px;
  color: #595D6E;
}
.text-muted{
  color: rgba(72, 70, 91, 0.5);
}
.rc-slider-track{
  background-color: #646c9a;
}
.rc-slider-handle{
  border-color: #646c9a!important;
  box-shadow: none!important;
}
`;

export default GlobalStyles;
