import { client, apiFormatter } from "utils/api";
import moment from "moment";

const getTodayTasks = current_user =>
  new Promise((resolve, reject) =>
    client
      .get(
        `/tasks?q[user_id_in][]=${current_user?.id}&q[time_to_gteq]=%25${moment().format(
          "YYYY-MM-DD"
        )}%25
        `
      )
      .then(({ data }) => {
        data = apiFormatter(data).map(item => ({
          ...item,
          time_from_unix: moment(item.time_from).unix(),
          time_from_to: moment(item.time_from_to).unix(),
        }));
        if (data?.length > 0) {
          const min = data?.reduce((item, next) =>
            item.time_from_unix < next.time_from_unix ? item : next
          );
          const max = data?.reduce((item, next) =>
            next.time_to_unix > item.time_to_unix ? next : item
          );

          resolve({ time_from: min.time_from, time_to: max.time_to });
        } else {
          resolve(null);
        }
      })
      .catch(err => {
        console.log(err);
        reject([]);
      })
  );

export default getTodayTasks;
