import React, { useEffect, useState } from "react";
import moment from "moment";
import { useQuery } from "react-query";
import { connect } from "react-redux";
import {
  Button,
  Checkbox,
  Input,
  Textarea,
} from "expano-components";
import {
  Main,
  Dashboard,
  Select,
  DatePicker,
  PageLoader,
} from "components";
import {
  Field,
  reduxForm,
  SubmissionError,
  formValueSelector,
} from "redux-form";
import { createTimeRecord } from "actions/time_records";
import { secondsToHms } from "utils/helpers";
import getUsers from "./const/getUsers";
import getTodayTasks from "./const/getTodayTasks";

import { ReactComponent as ClockIcon } from "icons/clock.svg";

const selector = formValueSelector("new-time-record");

const InputField = (props) => <Input {...props} />;
const TextAreaField = (props) => <Textarea {...props} />;
const CheckField = (props) => <Checkbox {...props} />;

const TimeRecordNew = ({
  current_user,
  time_from,
  time_to,
  pristine,
  handleSubmit,
  change,
  history,
}) => {
  const [diff, setDiff] = useState(0);
  const {
    isFetching: is_fetching_users,
    data: users,
  } = useQuery("users", getUsers);
  const {
    status,
    isFetching: is_fetching_today_tasks,
    data: today_tasks,
  } = useQuery("today_tasks", () =>
    getTodayTasks(current_user)
  );

  const submit = (values) =>
    createTimeRecord(values)
      .then(() => history.push("/time_records"))
      .catch((err) => {
        throw new SubmissionError(err);
      });

  useEffect(() => {
    if (time_from && time_to) {
      var duration = moment.duration(
        moment(time_to).diff(moment(time_from))
      );
      var seconds = duration.asSeconds();
      setDiff(seconds);
    }
  }, [time_from, time_to]);

  useEffect(() => {
    if (status === "success") {
      if (today_tasks?.time_from) {
        change("time_from", today_tasks.time_from);
      }
      if (today_tasks?.time_to) {
        change("time_to", today_tasks.time_to);
      }
    }
  }, [status]);

  if (is_fetching_today_tasks) return <PageLoader />;
  return (
    <Main
      page={{
        name: "Dodaj czas pracy",
        icon: <ClockIcon />,
        breadcrumbs: [
          { name: "Czasy pracy", path: "/time_records" },
          { name: "Dodaj czas pracy" },
        ],
      }}>
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <Dashboard>
            <form
              noValidate
              onSubmit={handleSubmit(submit)}>
              <div className="dashboard__form__submit">
                {current_user.is_admin && (
                  <>
                    <Field
                      name="user_id"
                      type="hidden"
                      component={InputField}
                    />
                    <Select
                      options={users}
                      isLoading={is_fetching_users}
                      label="Użytkownik"
                      initValue={
                        current_user
                          ? {
                              label: `${current_user.first_name} ${current_user?.last_name}`,
                              value: current_user.id,
                            }
                          : {}
                      }
                      onChange={(value) =>
                        change("user_id", value)
                      }
                    />
                  </>
                )}
                <Field
                  name="time_from"
                  label="Czas rozpoczęcia"
                  change={change}
                  component={(props) => (
                    <DatePicker {...props} />
                  )}
                />
                <Field
                  name="time_to"
                  label="Czas zakończenia"
                  change={change}
                  component={(props) => (
                    <DatePicker {...props} />
                  )}
                />
                <div className="form-group">
                  <div className="inp-wrapper">
                    <label
                      htmlFor="diff"
                      className="form-label">
                      Czas pracy
                    </label>
                    <div className="inp-group">
                      <input
                        id="diff"
                        name="diff"
                        type="text"
                        value={secondsToHms(diff)}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <Field
                  name="remote_work"
                  label="Praca zdalna"
                  component={CheckField}
                />
                {time_from && time_to && diff !== 8 && (
                  <Field
                    name="description"
                    label={
                      diff > 8
                        ? "Powód nadgodzin"
                        : "Powód mniejszej ilości godzin"
                    }
                    change={change}
                    component={TextAreaField}
                  />
                )}
              </div>
              <div className="dashboard__form__footer">
                <Button
                  type="cancel"
                  onClick={() =>
                    history.push("/time_records")
                  }
                />
                <Button
                  disabled={pristine}
                  type="save"
                  onClick={handleSubmit(submit)}
                />
              </div>
            </form>
          </Dashboard>
        </div>
      </div>
    </Main>
  );
};

export default connect((state) => ({
  current_user: state.current_user,
  time_from: selector(state, "time_from"),
  time_to: selector(state, "time_to"),
  initialValues: {
    user_id: state.current_user.id,
    time_from: new Date(
      moment().format("YYYY-MM-DD 00:00")
    ),
    time_to: new Date(moment().format("YYYY-MM-DD 00:00")),
    remote_work: false,
  },
}))(
  reduxForm({
    form: "new-time-record",
    enableReinitialize: true,
  })(TimeRecordNew)
);
