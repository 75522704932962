import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { CookiesProvider } from "react-cookie";
import ReduxToastr from "react-redux-toastr";
import store, { history } from "./store";
import GlobalStyles from "./theme/globalStyles";
import App from "./app";

import "sanitize.css/sanitize.css";
import "./styles/theme.scss";

render(
  <>
    <GlobalStyles />
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <CookiesProvider>
          <ReduxToastr
            timeOut={4000}
            newestOnTop={false}
            position='top-right'
            transitionIn='fadeIn'
            transitionOut='fadeOut'
            progressBar
            closeOnToastrClick
          />
          <App />
        </CookiesProvider>
      </ConnectedRouter>
    </Provider>
  </>,
  document.querySelector("#root")
);
