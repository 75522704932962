import React, { useState } from "react";
import moment from "moment";
import TextField from "@material-ui/core/TextField";

const DatePicker = ({
  input,
  meta,
  change,
  label,
  type = "datetime-local",
}) => {
  const [value, setValue] = useState(input.value);
  const formatDate = (date) =>
    moment(date).format(
      type === "datetime-local"
        ? "YYYY-MM-DDTHH:mm"
        : "YYYY-MM-DD"
    );

  return (
    <div
      className={`form-group  ${
        meta?.touched && meta?.invalid ? "error" : ""
      }`}>
      <div className="inp-wrapper">
        {label && (
          <label
            htmlFor={input.name}
            className="label-form">
            {label}
          </label>
        )}
        <div className="calendar-group">
          <TextField
            type={type}
            icon={null}
            defaultValue={formatDate(input.value)}
            onChange={({ target }) =>
              setValue(target.value)
            }
            onBlur={() => {
              change(input.name, value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </div>
      {meta?.touched && meta?.invalid && meta?.error && (
        <p className="inp-error">{meta.error}</p>
      )}
    </div>
  );
};

export default DatePicker;
